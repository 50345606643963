<template lang="pug">
  #hiv-consent.step-component
    h2 Consent Form

    <div id="docusign" style="display:flex; align-items: center; justify-content: center; padding: 0">
      img#spinner-icon(v-show="!showIframe && !finishDoc", src="~@/assets/img/loader.gif") 
    </div>

</template>

<script>
import Vue from 'vue'

export default {
  props: {
    formURL: { type: [Object, String], required: true },
  },
  data() {
    return {
        url: '',
        showIframe: false,
        finishDoc: false,
    }
  },
  computed: {},
  created: function() {
    this.getDocusignLink();
    window.addEventListener('message', this.listenMessage);
  },
  watch: {},
  methods: {
    getDocusignLink,
    listenMessage,
    gotoNext,
    formURLText,
    showIframeEvent
  },
  components: {},
}
/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function getDocusignLink() {
    const docusignStatus = this.$store.getters['user/getDocusignStatus'];
    if(docusignStatus) {
      return this.gotoNext();
    }
    const orderId = this.$store.getters['user/getMedplumOrderId'];
    const templateId = this.formURLText();
    if(orderId) {
      Vue.axios
        .post('/docusign/generate-link', {
            order_id: orderId,
            template_id: templateId
        })
        .then(postData => {
            this.url = postData.data.msg.url
            this.$store.commit('user/SET_DOCUSIGN_STATUS', true)
            const iframe = document.createElement("iframe");
            document.getElementById("docusign").appendChild(iframe);
            iframe.src = this.url
            iframe.style = "width: 100%; height: 100%; display: none";
            iframe.onload = this.showIframeEvent;
            iframe.id = "iframeDocusign";
        })
        .catch(postError => {
            console.error('Error : ', postError.message)
            this.$bugsnag.notify(postError)
        })
    }
}
function listenMessage(e) {
    if (typeof e.data === 'string' && e.data.includes("ds-callback")) {
        this.showIframe = false;
        this.finishDoc = true;
        this.gotoNext();
    }
}
function showIframeEvent() {
  document.getElementById("iframeDocusign").style.display = "block";
  this.showIframe = true;
}
function gotoNext() {
  this.$emit('complete')
}
function formURLText() {
  if (typeof this.formURL == 'object') {
    var keyValue = this.$store.getters['user/getConfigKeys'][this.formURL.key]
    return keyValue in this.formURL.config ? this.formURL.config[keyValue] : this.formURL.default
  } else {
    return this.formURL
  }
}
</script>